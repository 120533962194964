import React from 'react';
import Image from 'reusecore/src/elements/Image';
import { Link } from 'gatsby';
import DocumentationSection from '../../../containers/SaasModern/Documentation';
import ribbon from '../../../../../common/src/assets/image/ribbon.png';
import template_list from '../../../../../common/src/assets/image/template_list.png';
import template_options_month from '../../../../../common/src/assets/image/template_options_month.png';
import add_calendar from '../../../../../common/src/assets/image/add_calendar.png';
import data_source_appearance from '../../../../../common/src/assets/image/data_source_appearance.png';
import calendar_preset_select from '../../../../../common/src/assets/image/calendar_preset_select.png';
import tutorial_month_example from '../../../../../common/src/assets/image/tutorial_month_example.png';
import recent from '../../../../../common/src/assets/image/recent.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        PrintableCal is easy to learn, especially if you're already familiar
        with Microsoft Excel and Word. The steps below will walk you through
        getting started with PrintableCal quickly. If you have any questions,
        please <Link to="/#contact_us_section">contact us</Link>. We'd be happy
        to help.
      </p>
      <ol className="numbered">
        <li>
          PrintableCal is an add-in for Microsoft Word, Excel, and PowerPoint
          (Office 365, Office 2007 or later). After{' '}
          <Link to="/Download">installing PrintableCal</Link>, you should see a{' '}
          <strong>PrintableCal</strong> tab has been added to the ribbon. If you
          don't see the PrintableCal tab, see the{' '}
          <Link to="/Documentation/How-To/Install-PrintableCal#troubleshooting">
            troubleshooting information
          </Link>
          .
          <br />
          <Image alt="" src={ribbon} style={{ width: 858 }} />
        </li>
        <li>
          To create a calendar, click the <strong>Create Calendar</strong>{' '}
          button on the ribbon. A side panel will appear listing all the
          available templates. If the <strong>Show example carousel</strong> box
          is checked, a window will show a preview of the selected template. To
          select a different template, use the arrow buttons on the example
          carousel or click a different template in the list. <br />
          <br />
          The template list can be filtered to only show templates that are
          associated with a keyword or phrase. Enter the desired search term in
          the Search box provided above the template list. <br />
          <br />
          You can also right-click templates you use often, and select{' '}
          <strong>Add to Favorites</strong> to make those templates appear at
          the top of the list under the <strong>Favorites </strong>category.
          Depending on which version of PrintableCal you've installed, you can
          also{' '}
          <Link to="/Documentation/How-To/Customize-a-Template">
            create your own templates or customize the existing ones
          </Link>
          .<br />
          <br />
          After selecting the desired template, click the <strong>Next </strong>
          button in the bottom-right corner or the <strong>Select</strong>{' '}
          button on the example carousel.
          <br />
          <Image alt="" src={template_list} />
        </li>
        <li>
          The next screen will provide settings for the selected template. The{' '}
          <Link to="/Documentation/Create-Calendar/Templates-and-Options">
            available settings
          </Link>{' '}
          will vary depending on which template was selected. Adjust the
          settings as desired and then click the <strong>Next</strong> button.
          <br />
          <Image alt="" src={template_options_month} style={{ width: 444 }} />
        </li>
        <li>
          On the next page, you can specify which calendars should be included
          in the output. The first time you run PrintableCal, the calendar list
          will be empty. To add a calendar, click the{' '}
          <strong>Add Calendar</strong> button. Calendars can be added from{' '}
          <Link to="/Documentation/Create-Calendar/Calendar-Data-Sources">
            many sources
          </Link>
          , including Google Calendar, iCloud, VueMinder, Outlook, and more. The
          checkboxes control which calendars will appear in the output. If
          multiple calendars are selected, their events will be overlaid into
          the same template. <br />
          <Image alt="" src={add_calendar} style={{ width: 444 }} />
          Note, the different versions of PrintableCal have different limits on
          the number of calendars that can be overlaid into a single template.
          The Lite version will only show placeholders with "Please Upgrade"
          text. The Basic version is limited to 1 calendar. The Standard version is limited to 3 calendars. The Premium
          version is limited to 6 calendars. The Ultimate version has no
          limitation on the number of calendars.
        </li>
        <li>
          After adding a calendar, you can adjust the calendar's name, color,
          font, border, and item prefix. Click the calendar's name to access
          these settings.
          <br />
          <Image alt="" src={data_source_appearance} style={{ width: 456 }} />
        </li>
        <li>
          If there are different combinations of calendar data sources you'd
          like to easily switch between, you can save these as presets. Enter a
          name for the preset in the<strong>Preset</strong>
          field (below the template list) and click th <strong>
            Save
          </strong>{' '}
          button. To select a previously saved preset, enter its name or pick
          from the drop-down list. Any calendars associated with the preset will
          be checked and included in the output. Other calendars will be
          unchecked and not included.
          <br />
          <Image alt="" src={calendar_preset_select} style={{ width: 456 }} />
        </li>
        <li>
          Click the <strong>Done </strong>button to generate the template. This
          might take a few seconds, depending on which template was selected and
          how many calendars are included in the output. A progress bar will
          appear while the template is being generated. After the output is
          generated, you can save or print the output.
          <br />
          <Image alt="" src={tutorial_month_example} />
        </li>
        <li>
          If you'd like to generate the same template again, using the same
          calendars and settings specified previously, you can use the{' '}
          <strong>Quick Calendar</strong> button on the PrintableCal tab of the
          ribbon. There's also a <strong>Recent</strong> button which enables
          regeneration of the 8 most recently selected templates. PrintableCal
          will remember which settings and calendars were selected for each
          template in the Recent template list. Some templates, such as the Day
          template, will automatically adjust to the current date when selected
          using the Quick Calendar or Recent button, making calendar generation
          a simple 1-button click.
          <br />
          <Image alt="" src={recent} style={{ width: 456 }} />
        </li>
        <li>
          That's basically all there is to generating calendars with
          PrintableCal. However, there are many other things you can do with
          PrintableCal, such as inserting{' '}
          <Link to="/Documentation/Insert-Legend">calendar legends</Link>,{' '}
          <Link to="/Documentation/How-To/Customize-a-Template">
            customizing templates
          </Link>
          ,{' '}
          <Link to="/Documentation/Import-Template">
            importing/exporting custom templates
          </Link>
          , and more.
        </li>
      </ol>
      <p>
        We hope you'll enjoy using PrintableCal. If you have any questions or
        suggestions, please <Link to="/#contact_us_section">contact us</Link>.
        We'll be happy to help.
      </p>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Quick-Start"
      commentsId="commentsplus_post_119_489"
      title="Quick Start"
      description="PrintableCal is easy to learn and install. It can create printable calendars using Microsoft Excel or Word. It can also add color-coded calendar legends."
      keywords="Excel calendar, Word calendar, calendar add-in, create printable calendar, print holidays, print Outlook, calendar legend, custom calendar, calendar template, 2020 calendar"
      content={content}
    />
  );
};

export default Documentation;
